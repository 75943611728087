.context-variables {
  margin: 20px 0;
  display: flex;
  align-items: center;

  h3 {
    font-size: 18px;
    font-weight: 700;

    &::before {
      color: #e32;
      content: '*';
      display: inline;
      margin-right: 2px;
    }

    p {
      margin: 0;
      padding: 0;
      display: inline;
      font-weight: 300;
      font-size: 16px;

      .variable {
        margin-right: 8px;
        display: inline-block;

        span {
          font-size: 12px;
          margin-right: 0;
        }
      }
    }
  }
}