@import './variables.scss';
@import './common.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'OpenSans', 'Helvetica Neue', sans-serif;
  min-height: 100vh;
  line-height: 1.5;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background-color: $color-grey-light-1;
  color: $color-black;
  font-size: 14px;
}

.jodit-toolbar-button_fullsize {
  display: none;
}
