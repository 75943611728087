@import '../../assets/styles/variables.scss';

.sign-in-page {
  width: 100%;
  height: 100%;
  min-height: 100vh;

  .info-box {
    flex-direction: column;
    width: 600px;
    height: 300px;
    padding: 20px;
    border-radius: 5px;
    background-color: $color-grey-dark-1;
    color: $color-white;
    font-size: 30px;
    text-align: center;

    h2 {
      font-size: 30px;
      color: inherit;
      margin-bottom: 15px;
    }

    button {
      font-size: 18px;
    }
  }
}
