@import './variables.scss';

.buttons-group {
  margin-top: 10px;
  display: flex;
  align-items: center;

  > * {
    margin-right: 10px;
  }
}

.flex-center-elements {
  display: flex;
  align-items: center;
  justify-content: center;
}
