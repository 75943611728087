.searchbar {
  padding: 10px 0 20px 0;

  .service-dropdown {
    min-width: 200px;
  }

  .input {
    flex: 1;
  }

  .submit-btn {
    margin-right: 0;
  }
}
