@import '../../assets/styles/variables.scss';

.container {
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 30px;
  width: 1200px;
  padding: 0 15px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .page-header {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 20px;
  }

  .page-header-btn {
    margin-bottom: 20px;
  }

  .content {
    background-color: $color-white;
    padding: 24px;
    min-height: 280px;
  }
}
