@import '../../assets/styles/variables.scss';

.header {
  align-items: center;
  background-color: $color-grey-dark-1;
  color: $color-white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 75px;
  width: 100%;

  > svg {
    margin-left: 80px;
  }

  svg {
    fill: $color-white;
  }

  .status-bar {
    color: $color-white;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    .status-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      padding: 0 25px;
      width: 100%;

      .user-info {
        margin-left: auto;
      }

      > span {
        margin-left: 25px;
      }

      .btn-logout {
        color: $color-white;
        text-decoration: underline;
      }
    }

    .powered-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 12px 0;
      height: 100%;
      width: 100px;
    }
  }
}
