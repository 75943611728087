@import '../../assets/styles/variables.scss';

.templates-list {
  margin-bottom: 15px;
}

.create-btn {
  border-color: $color-green-light-1 !important;
  background-color: $color-green-light-1 !important;

  &:hover {
    border-color: lighten($color-green-light-1, 10%) !important;
    background-color: lighten($color-green-light-1, 10%) !important;
  }
}
